import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const WriteForUs = () => (
  <Layout>
    <SEO title="Write For Us" />
    <h1>Write For Us</h1>
    <hr/>
    <p>Everyone interested can write guest posts for CodeSpot, we want the best content for our readers. We are covering every topic about Software Development and Programming, share your expertise and be appreciated by our visitors.</p>
    <p>For guest posting, we allow one link to a website of your choice and social media link.</p>

    <ul>
      <li>It is a topic we agree on.</li>
      <li>The article is a minimum of 1000 words.</li>
      <li>The article must be unique, the content will be ran through plagiarism checkers.</li>
    </ul>

    <p>Contact us at code.spot.2019@gmail.com to discuss more.</p>
    <h4>The CodeSpot Team.</h4>
    <hr/>
  </Layout>
)

export default WriteForUs